import { render, staticRenderFns } from "./ShapeData.vue?vue&type=template&id=574143a7&scoped=true&"
import script from "./ShapeData.vue?vue&type=script&lang=js&"
export * from "./ShapeData.vue?vue&type=script&lang=js&"
import style0 from "./ShapeData.vue?vue&type=style&index=0&id=574143a7&prod&lang=less&scoped=true&"
import style1 from "./ShapeData.vue?vue&type=style&index=1&id=574143a7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574143a7",
  null
  
)

export default component.exports