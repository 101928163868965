<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-06-30 17:44:21
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-04-09 16:51:42
-->
<template>
    <!--身体成分下半部分-->
    <div>
        <div class="segment-fat-data">
            <div class="thermal-title">
                <span></span>
            </div>
            <div v-for="(item, index) in newDataArray" :key="index">
                <bodydata-item  v-if="isSpecial(item)" :chart="item" :index=index
                    :styleChildren="styleContent"></bodydata-item>
            </div>
        </div>
    </div>
</template>
<script>
import BodydataItem from '@/components/common/BodyDataItem'
import { findBmMassQuotaInfos, findBmBIAScanTrueHistory } from '@/assets/js/apolloGql.js'
import { clone, formatDate, formatTime, _toDecimal } from '@/assets/js/util.js'
import { mapState } from "vuex"
import { E_M30_REPORT_ITEM } from '@/types/device.js'
export default {
    props: {
        massInfo: Object,
        styleContent: Number
    },
    components: {
        BodydataItem
    },
    data() {
        return {
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            ytb: false,
            select: false,
            selectTime: '',
            lastSelectTime: '',
            timePopupVisible: false,
            timeList: [],
            scanId: '',
            tcScanId: '',
            originDate: '', // 初始日期
            isMoreTime: true,
            unit: window.localStorage.getItem('unit'),
            newDataArray: [],
            deviceType: JSON.parse(window.localStorage.getItem('deviceType'))
        }
    },
    computed: {
        ...mapState(['composition', "historyScanId", 'historyData']),
    },
    watch: {
        composition: {
            // eslint-disable-next-line no-unused-vars
            handler: function (val) {
                this.newDataArray = val
                console.log(this.newDataArray)
            },
            deep: true,
        }
    },
    mounted() {
        if (this.historyScanId) {
            this.scanId = this.historyScanId
        } else {
            this.scanId = this.modelInfo.scanId
        }
        let compareDataStorage = window.sessionStorage.getItem('compareData')
        let savedCompareData = compareDataStorage ? JSON.parse(compareDataStorage) : null
        if (savedCompareData) {
            this.selectTime = savedCompareData.date || ''
            this.defaultScanId = savedCompareData.scanId || ''
        }
        // 新用户没有历史数据时调用
        this.findBmBIAScanTrueHistory()
        console.log(`当前item${E_M30_REPORT_ITEM}`)
        // this.$hmt.trackPageview('/tab/body')
    },
methods: {
        isSpecial(item) {
            if (this.deviceType === 3) {
                // m30 只显示四项
                return E_M30_REPORT_ITEM.includes(item.dataColumn)
            }
            if (this.modelInfo.deviceId) {
                return !(this.modelInfo.deviceId.slice(0, 2) !== '37' && (item.dataColumn === 'ICW' || item.dataColumn === 'ECW'))
            } else {
                return true
            }
        },
        isStatus(item) {
            return item.dataColumn === 'ICW' || item.dataColumn === 'ECW'
        },
        toDecimal(num, digit) {
            return _toDecimal(num * 0.45359237, digit)
        },
        // 获取用户体测BIA合成成功历史记录
        findBmBIAScanTrueHistory() {
            this.$apollo
                .query({
                    query: findBmBIAScanTrueHistory,
                    variables: {
                        memberId: this.modelInfo.memberId,
                        scanTime: this.massInfo.createTime,
                        deviceType: this.deviceType
                    }
                })
                .then(res => {
                    const data = res.data.findBmBIAScanTrueHistory
                    if (data.code === 200) {
                        let historyDate = []
                        for (let index = 0; index < data.data.length; index++) {
                            for (let aa = 0; aa < data.data[index].data.length; aa++) {
                                historyDate.push(data.data[index].data[aa])
                            }
                        }
                        this.$store.commit('setDataArrayMass', historyDate)
                        this.timeList = clone(data.data)
                        // eslint-disable-next-line no-unused-vars
                        this.timeList.forEach((time, index) => {
                            time.options = []
                            // eslint-disable-next-line no-unused-vars
                            this.$lodash.each(time.data, (item, itemIndex) => {
                                time.options.push({
                                    scanId: item.scanId,
                                    label: formatDate(new Date(item.createDate)),
                                    value: formatTime(new Date(item.createDate)),
                                    disabled: false,
                                    isChecked: false
                                })
                            })
                            time.options.reverse()
                        })
                        // 数组倒序排列
                        this.timeList.reverse()

                        // 时间组长度
                        let timeLen = this.timeList.length
                        // 最后一组时间
                        let lastData = this.timeList[timeLen - 1].options
                        let lastDataLen = lastData.length
                        // 本次扫描信息
                        lastData[lastDataLen - 1].disabled = true
                        this.lastSelectTime = lastData[lastDataLen - 1].value
                        this.scanId = lastData[lastDataLen - 1].scanId
                        window.localStorage.setItem('BmLastScanId', this.scanId)

                        // 最后一组时间有对比
                        if (lastDataLen >= 2) {
                            // 是否存在缓存数据
                            this.isDefaultDate(lastData)
                            lastData[lastDataLen - 2].isChecked = true
                            this.defaultScanId = lastData[lastDataLen - 2].scanId
                            this.selectTime = lastData[lastDataLen - 2].value
                            // 上一组时间判断
                        } else if (timeLen >= 2) {
                            let preData = this.timeList[timeLen - 2].options
                            // 是否存在缓存数据
                            this.isDefaultDate(preData)
                            let preDataLen = preData.length
                            preData[preDataLen - 1].isChecked = true
                            this.defaultScanId = preData[preDataLen - 1].scanId
                            this.selectTime = preData[preDataLen - 1].value
                        } else {
                            this.isMoreTime = false
                        }
                        this.originDate = this.selectTime

                        this.bmMassContrasInfo()
                    }
                })
        },
        isDefaultDate(data) {
            if (this.selectTime && this.defaultScanId) {
                if (this.initExistDate(data)) {
                    this.bmMassContrasInfo()
                    return
                }
            }
        },
        // 缓存中有数据，直接从缓存拉去
        // eslint-disable-next-line no-unused-vars
        initExistDate(dateArray) {
            // 有可能缓存的时间不在数组中
            let isChanged = false
            for (let i = 0; i < this.timeList.length; i++) {
                for (let j = 0; j < this.timeList[i].options.length; j++) {
                    if (
                        this.timeList[i].options[j].scanId === this.defaultScanId &&
                        this.timeList[i].options[j].value === this.selectTime
                    ) {
                        this.timeList[i].options[j].isChecked = true
                        isChanged = true
                        this.originDate = this.selectTime
                        break
                    }
                }
            }
            return isChanged
        },

        // 缓存对比日期和scanid
        saveStorage(date, scanId) {
            window.sessionStorage.setItem(
                'compareData',
                JSON.stringify({
                    date,
                    scanId
                })
            )
        },

        bmMassContrasInfo() {
            this.$apollo
                .query({
                    query: findBmMassQuotaInfos,
                    variables: {
                        memberId: this.modelInfo.memberId,
                        scanId: this.scanId,
                        tcScanId: this.scanId,
                        deviceType: this.deviceType
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.findBmMassQuotaInfos
                    console.log(data)
                    if (data.code === 200) {
                        const newArray = clone(data.data)
                        // newArray = [...data.data, data.data[data.data.length - 1]];
                        newArray.forEach(item => {
                            if (item.contrastData) {
                                item.contrast = item.contrastData.v
                            } else {
                                item.contrast = null
                            }
                            if (this.unit === 'metric' && item.unit === 'kg') {
                                if (item.latestData) item.latestData.v = this.toDecimal(item.latestData.v, 1)
                                if (item.contrastData) {
                                    item.contrastData.v = this.toDecimal(item.contrastData.v, 1)
                                    item.contrast = item.contrastData.v || null
                                }
                            }
                            item.lates = item.latestData.v || null
                            if (this.unit === 'imperial' && item.unit === 'kg') {
                                item.unit = 'lbs'
                            }
                        })
                        const age = newArray.filter((item) => item.dataColumn === 'METABOLICAGE')
                        const newIndex = newArray.findIndex(item => item.dataColumn === 'BMR')
                        newArray.splice(newIndex+1, 0, age[0])
                        newArray.splice(newArray.length - 1)

                        this.$store.commit("setComposition", newArray)
                    }
                })
        },
    }
}
</script>

<style lang="less" scoped>
.body-composition {

    // 禁止部分安卓机下图片自动放大
    img {
        pointer-events: none;
    }

    padding: 0 13px;

    // min-height: 525px;
    .btn-action {
        margin-top: 18px;
        margin-bottom: 20px;

        img {
            width: 211px;
            height: 52px;
        }
    }

    .standard-text {
        display: flex;
        justify-content: space-around;
        color: rgba(255, 255, 255, 0.4);
        font-size: 12px;
    }

    >ul {
        text-align: left;

        li.composition-item:nth-child(5) {
            .body-scale {
                >ul {
                    li {
                        &:nth-child(32) {
                            height: 28px;
                            margin-top: -14px;
                        }

                        &:nth-child(22),
                        &:nth-child(43) {
                            height: 0;
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        >li {
            list-style: none;
            color: #fff;
            font-size: 10px;
            height: 110px;
            border-radius: 5px;
            background-image: linear-gradient(to right, #13162e, #0f132a, #12142e, #13162e);
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
            margin-bottom: 10px;
            padding: 0 10px;
            position: relative;

            .scale-lift-color {
                background-color: #069cfa !important;
            }

            .scale-lift {
                position: absolute;
                right: 10px;
                top: 0;
                width: 20px;
                height: 22px;
                line-height: 22px;
                text-align: center;
                border-bottom-left-radius: 9px;
                border-bottom-right-radius: 9px;
                background-color: #fcb127;
                color: #fff;
            }

            p.name {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 21px;
                padding-top: 9px;

                img {
                    &:first-child {
                        min-width: 21px;
                        min-height: 21px;
                        margin-right: 4px;
                        padding-left: 0;
                    }

                    padding-left: 12px;
                }
            }

            .body-scale {
                position: relative;

                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 100%;
                    height: 20px;
                    background-color: #0d0f19;
                    top: 18px;
                    z-index: 5;
                }
            }
        }
    }
}

.thermal-title {
    margin-bottom: 10px;
}
</style>

