<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-06-29 16:28:46
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-07-19 19:59:19
-->
<template>
    <div>
        <div :class="['body-assessment-list', $i18n.locale]">
            <!-- 头前引 -->
            <shapedata-item :item="headForward" @handelHelp="handelHelp"></shapedata-item>
            <!-- 头侧歪 -->
            <shapedata-item :item="headSlant" @handelHelp="handelHelp"></shapedata-item>
            <!-- 左圆肩 -->
            <shapedata-item :item="roundShoulder.left" @handelHelp="handelHelp"></shapedata-item>
            <!-- 右圆肩 -->
            <shapedata-item :item="roundShoulder.right" @handelHelp="handelHelp"></shapedata-item>
            <!-- 高低肩 -->
            <shapedata-item :item="highLowShoudler" @handelHelp="handelHelp"></shapedata-item>
            <!-- 骨盆前/后移 -->
            <shapedata-item :item="pelvisForward" @handelHelp="handelHelp"></shapedata-item>
            <!-- 左膝评估 -->
            <shapedata-item :item="knee.left" @handelHelp="handelHelp"></shapedata-item>
            <!-- 右膝评估 -->
            <shapedata-item :item="knee.right" @handelHelp="handelHelp"></shapedata-item>
            <!-- 腿型 -->
            <div :class="['data-list',$i18n.locale]">
                <div class="thermal-item">
                   <div class="heard">
                     <i>|</i>
                    <span class="name">
                        {{ $t('shape.explain[8].title') }}
                        <img src="../../assets/images/report/help.png" alt
                            @click.prevent="handelHelp($t('shape.explain[8].title'))" />
                    </span>
                   </div>
                    <div class="shape-list">
                        <span class="title">{{ $t('shape.item.deviate') }}</span>
                        <div alt="" class="symbol-ico"></div>
                        <span v-if="$i18n.locale==='ar-AR'" :class="['val-list', { 'val-color': leg.status !== $t('shape.item.normal') }]">{{ ( leg.left.val || onHandler ) + ':L'}}-{{ ( leg.right.val || onHandler ) + ':R'}}</span>
                        <span v-else :class="['val-list', { 'val-color': leg.status !== $t('shape.item.normal') }]">{{ 'L:' +
                            leg.left.val | onHandler }}-{{ 'R:' + leg.right.val | onHandler }}</span>
                        <div alt="" class="symbol-ico"></div>
                        <span class="shape-status">
                            <span :class="['status', { 'status-color': leg.status !== $t('shape.item.normal') }]">{{
                                leg.status }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!--体态评估-弹出说明-->
        <van-popup v-model="popupAssess" class="body-composition-popup" popup-transition="popup-fade">
            <!-- 防止在pupop里滚动，body也跟着滚 -->
            <div class="popup-container" @touchmove.prevent>
                <h5>{{ form.name }} {{ $t('shape.item.result') }}</h5>
                <div class="first-intro content">
                    <p>{{ form.desc }}</p>
                </div>
                <div class="pop-btn-action" @click="popupAssess = false">{{ $t('model.know') }}</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { findBsEvalContrastInfo } from '@/assets/js/apolloGql.js'
import { toDecimal2, _toDecimal } from '@/assets/js/util.js'
import ShapedataItem from '@/components/common/ShapeDataItem'
export default {
    components: {
        ShapedataItem
    },
    props: {
        notMeasured: Boolean,
        measuredFailed: Boolean,
        notComposition: Boolean,
        compositionFailed: Boolean,
        massInfo: Object
    },
    filters: {
        onHandler(value) {
            if (value.indexOf('undefined') > -1) {
                return '-'
            } else {
                return value
            }
        }
    },
    data() {
        return {
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            popupAssess: false,
            body: {},
            form: {
                name: '',
                desc: '',
                advice: '',
                result: 0,
                status: ''
            },
            problemList: [],
            isShow: this.notComposition,
            bsNoMeasure: false,
            loading: true,
            evalInfo: {
                headForward: { name: "" },
                headSlant: { name: "" },
                highLowShoudler: { name: "" },
                leftKneeCheck: { name: "" },
                leftLegXo: { name: "" },
                pelvisForward: { name: "" },
                rightKneeCheck: { name: "" },
                rightLegXo: { name: "" },
                roundShoulderLeft: { name: "" },
                roundShoulderRight: { name: "" },
            },
            unit: window.localStorage.getItem('unit'),
        }
    },
    computed: {
        // 头前引
        headForward() {
            const info = this.evalInfo['headForward']
            if (!info) {
                return {}
            }
            const val = info.val
            // 结论
            const status = val > 0 ? this.$t('shape.item.abnormal') : this.$t('shape.item.normal')
            return {
                name: this.$t('shape.explain[0].title'),
                val: val + '°',
                diff: info.diff !== '--' ? `${info.diff >= 0 ? '+' + info.diff : info.diff}°` : info.diff,
                status: status
            }
        },
        // 头侧歪
        headSlant() {
            const info = this.evalInfo['headSlant']
            if (!info) {
                return {}
            }
            const val = info.val
            // 结论
            const status = val > 0 ? this.$t('shape.exception[0].title') : val < 0 ? this.$t('shape.exception[1].title') : this.$t('shape.item.normal')
            return {
                name: this.$t('shape.explain[1].title'),
                val: val + '°',
                diff: info.diff !== '--' ? `${info.diff >= 0 ? '+' + info.diff : info.diff}°` : info.diff,
                status: status
            }
        },
        // 圆肩
        roundShoulder() {
            const leftInfo = this.evalInfo['roundShoulderLeft']
            const rightInfo = this.evalInfo['roundShoulderRight']
            if (!leftInfo || !rightInfo) {
                return {}
            }
            const leftVal = leftInfo.val
            // 结论
            const leftStatus = leftVal > 15 ? this.$t('shape.item.abnormal') : this.$t('shape.item.normal')
            const left = {
                name: this.$t('shape.explain[2].title'),
                val: leftVal + '°',
                diff:
                    leftInfo.diff !== '--'
                        ? `${leftInfo.diff >= 0 ? '+' + leftInfo.diff : leftInfo.diff}°`
                        : leftInfo.diff,
                status: leftStatus
            }

            const rightVal = rightInfo.val
            // 结论
            const rightStatus = rightVal > 15 ? this.$t('shape.item.abnormal') : this.$t('shape.item.normal')
            const right = {
                name: this.$t('shape.explain[3].title'),
                val: rightVal + '°',
                diff:
                    rightInfo.diff !== '--'
                        ? `${rightInfo.diff >= 0 ? '+' + rightInfo.diff : rightInfo.diff}°`
                        : rightInfo.diff,
                status: rightStatus
            }
            return {
                left: left,
                right: right
            }
        },
        // 高低肩
        highLowShoudler() {
            const info = this.evalInfo['highLowShoudler']
            if (!info) {
                return {}
            }
            const val = this.unit === 'metric' ? _toDecimal(info.val * 2.54, 1) : info.val
            // 结论
            const status = val > 0 ? this.$t('shape.exception[2].title') : val < 0 ? this.$t('shape.exception[3].title') : this.$t('shape.item.normal')
            return {
                name: this.$t('shape.explain[4].title'),
                val:  val + `${this.$i18n.locale==='ar-AR'?this.unit === 'imperial' ? 'بوصة' : 'سم' :  this.unit === 'imperial' ? 'in.' : 'cm'}`,
                diff: info.diff !== '--' ? `${info.diff >= 0 ? '+' + info.diff : info.diff}cm` : info.diff,
                status: status
            }
        },
        // 骨盆前/后移
        pelvisForward() {
            const info = this.evalInfo['pelvisForward']
            if (!info) {
                return {}
            }
            const val = info.val
            // 结论
            const status = val > 185 ? this.$t('shape.exception[4].title') : val < 175 ? this.$t('shape.exception[5].title') : this.$t('shape.item.normal')
            return {
                name: this.$t('shape.explain[5].title'),
                val: val + '°',
                diff: info.diff !== '--' ? `${info.diff >= 0 ? '+' + info.diff : info.diff}°` : info.diff,
                status: status
            }
        },
        // 膝盖评估
        knee() {
            const leftInfo = this.evalInfo['leftKneeCheck']
            const rightInfo = this.evalInfo['rightKneeCheck']
            if (!leftInfo || !rightInfo) {
                return {}
            }
            const leftVal = leftInfo.val
            // 结论
            const leftStatus = leftVal > 190 ? this.$t('shape.exception[6].title') : leftVal < 160 ? this.$t('shape.exception[7].title') : this.$t('shape.item.normal')
            const left = {
                name: this.$t('shape.explain[6].title'),
                val: leftVal + '°',
                diff:
                    leftInfo.diff !== '--'
                        ? `${leftInfo.diff >= 0 ? '+' + leftInfo.diff : leftInfo.diff}°`
                        : leftInfo.diff,
                status: leftStatus
            }

            const rightVal = rightInfo.val
            // 结论
            const rightStatus = rightVal > 190 ? this.$t('shape.exception[8].title') : rightVal < 160 ? this.$t('shape.exception[9].title') : this.$t('shape.item.normal')
            const right = {
                name: this.$t('shape.explain[7].title'),
                val: rightVal + '°',
                diff:
                    rightInfo.diff !== '--'
                        ? `${rightInfo.diff >= 0 ? '+' + rightInfo.diff : rightInfo.diff}°`
                        : rightInfo.diff,
                status: rightStatus
            }
            return {
                left: left,
                right: right
            }
        },
        // 腿型
        leg() {
            const leftInfo = this.evalInfo['leftLegXo']
            const rightInfo = this.evalInfo['rightLegXo']
            if (!leftInfo || !rightInfo) {
                return { left: { val: '' }, right: { val: '' } }
            }
            const leftVal = leftInfo.val
            const rightVal = rightInfo.val
            // 结论
            let status = ''
            if (leftVal <= 190 && leftVal >= 170) {
                if (rightVal <= 190 && rightVal >= 170) {
                    status = this.$t('shape.item.normal')
                } else if (rightVal >= 0 && rightVal < 170) {
                    status = this.$t('shape.exception[10].title')
                } else if (rightVal > 190 && rightVal <= 360) {
                    status = this.$t('shape.exception[11].title')
                }
            } else if (leftVal >= 0 && leftVal < 170) {
                if (rightVal <= 190 && rightVal >= 170) {
                    status = this.$t('shape.exception[10].title')
                } else if (rightVal >= 0 && rightVal < 170) {
                    status = this.$t('shape.exception[12].title')
                } else if (rightVal > 190 && rightVal <= 360) {
                    status = this.$t('shape.item.abnormal')
                }
            } else if (leftVal > 190 && leftVal <= 360) {
                if (rightVal <= 190 && rightVal >= 170) {
                    status = this.$t('shape.exception[11].title')
                } else if (rightVal >= 0 && rightVal < 170) {
                    status = this.$t('shape.item.abnormal')
                } else if (rightVal > 190 && rightVal <= 360) {
                    status = this.$t('shape.exception[13].title')
                }
            }

            const left = {
                val: leftVal + '°',
                diff:
                    leftInfo.diff !== '--'
                        ? `${leftInfo.diff >= 0 ? '+' + leftInfo.diff : leftInfo.diff}°`
                        : leftInfo.diff
            }

            const right = {
                val: rightVal + '°',
                diff:
                    rightInfo.diff !== '--'
                        ? `${rightInfo.diff >= 0 ? '+' + rightInfo.diff : rightInfo.diff}°`
                        : rightInfo.diff
            }
            return {
                left: left,
                right: right,
                status: status
            }
        },
    },
    mounted() {
        this.findBsEvalContrastInfo()
        if (this.modelInfo.staticEval === 0) {
            this.bsNoMeasure = true
        } else if (this.modelInfo.evalStatus === 1) {
            this.findBsEvalContrastInfo()
        } else if (this.modelInfo.evalStatus === 0) {
            this.isShow = true
            this.$emit('isBsStatus', this.isShow)
        }
    },
    methods: {
        toDecimal(num, digit) {
            return toDecimal2(num, digit)
        },
        // 体态评估
        findBsEvalContrastInfo() {
            let shareScanId = window.localStorage.getItem("shareScanId")
            //  分享链接进入取分享报告ScanId
            let shareFlag = !JSON.parse(localStorage.getItem('share')) && shareScanId

            this.$apollo
                .query({
                    query: findBsEvalContrastInfo,
                    variables: {
                        scanId: shareFlag ? shareScanId : this.massInfo.scanId,
                        scanTime: this.massInfo.createTime
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.findBsEvalContrastInfo
                    const bsEvalContrastInfo = data.data
                    this.loading = false
                    if (data.code === 200 && bsEvalContrastInfo.length) {
                        let bsEvalInfo = JSON.parse(JSON.stringify(bsEvalContrastInfo[0]))
                        let evalInfo = {}
                        for (let key in bsEvalInfo) {
                            if (Object.prototype.hasOwnProperty.call(bsEvalInfo, key)) {
                                if (typeof bsEvalInfo[key] === 'number') {
                                    let evalItem = {
                                        val: this.toDecimal(bsEvalInfo[key], 1),
                                        diff: '--'
                                    }
                                    // 有对比值
                                    if (bsEvalContrastInfo[1] && bsEvalContrastInfo[1][key]) {
                                        const diff = bsEvalInfo[key] - bsEvalContrastInfo[1][key]
                                        evalItem.diff = toDecimal2(diff, 1)
                                    }
                                    evalInfo[key] = evalItem
                                }
                            }
                        }
                        this.evalInfo = evalInfo
                        if (bsEvalInfo.headForward > 0) {
                            this.problemList.push(this.$t('shape.item.head'))
                        }
                        if (bsEvalInfo.headSlant > 0 || bsEvalInfo.headSlant < 0) {
                            this.problemList.push(this.$t('shape.item.headSlant'))
                        }
                        if (bsEvalInfo.roundShoulderLeft > 15) {
                            this.problemList.push(this.$t('shape.item.roundShoulderLeft'))
                        }
                        if (bsEvalInfo.roundShoulderRight > 15) {
                            this.problemList.push(this.$t('shape.item.roundShoulderRight'))
                        }
                        if (bsEvalInfo.highLowShoudler > 0 || bsEvalInfo.highLowShoudler < 0) {
                            this.problemList.push(this.$t('shape.item.highLowShoudler'))
                        }
                        if (bsEvalInfo.pelvisForward > 185) {
                            this.problemList.push(this.$t('shape.exception[4].title'))
                        } else if (bsEvalInfo.pelvisForward < 175) {
                            this.problemList.push(this.$t('shape.exception[5].title'))
                        }
                        if (bsEvalInfo.leftKneeCheck > 190) {
                            this.problemList.push(this.$t('shape.exception[6].title'))
                        } else if (bsEvalInfo.leftKneeCheck < 160) {
                            this.problemList.push(this.$t('shape.exception[7].title'))
                        }
                        if (bsEvalInfo.rightKneeCheck > 190) {
                            this.problemList.push(this.$t('shape.exception[8].title'))
                        } else if (bsEvalInfo.rightKneeCheck < 160) {
                            this.problemList.push(this.$t('shape.exception[9].title'))
                        }
                        if (bsEvalInfo.leftLegXo <= 190 && bsEvalInfo.leftLegXo >= 170) {
                            if (bsEvalInfo.rightLegXo >= 0 && bsEvalInfo.rightLegXo < 170) {
                                this.problemList.push(this.$t('shape.exception[10].title'))
                            } else if (bsEvalInfo.rightLegXo > 190 && bsEvalInfo.rightLegXo <= 360) {
                                this.problemList.push(this.$t('shape.exception[11].title'))
                            }
                        } else if (bsEvalInfo.leftLegXo >= 0 && bsEvalInfo.leftLegXo < 170) {
                            if (bsEvalInfo.rightLegXo <= 190 && bsEvalInfo.rightLegXo >= 170) {
                                this.problemList.push(this.$t('shape.exception[10].title'))
                            } else if (bsEvalInfo.rightLegXo >= 0 && bsEvalInfo.rightLegXo < 170) {
                                this.problemList.push(this.$t('shape.exception[12].title'))
                            }
                        } else if (bsEvalInfo.leftLegXo > 190 && bsEvalInfo.leftLegXo <= 360) {
                            if (bsEvalInfo.rightLegXo <= 190 && bsEvalInfo.rightLegXo >= 170) {
                                this.problemList.push(this.$t('shape.exception[11].title'))
                            } else if (bsEvalInfo.rightLegXo > 190 && bsEvalInfo.rightLegXo <= 360) {
                                this.problemList.push(this.$t('shape.exception[13].title'))
                            }
                        }
                        this.$emit('assessProblem', bsEvalInfo)
                    } else {
                        this.isShow = true
                        this.$emit('isBsStatus', this.isShow)
                    }
                })
        },
        handelHelp(name) {
            console.log(this.form, '-------')
            this.form.name = name
            if (name === this.$t('shape.explain[0].title')) {
                this.form.desc = this.$t('shape.explain[0].msg')
            }
            if (name === this.$t('shape.explain[1].title')) {
                this.form.desc = this.$t('shape.explain[1].msg')
            }
            if (name === this.$t('shape.explain[2].title')) {
                this.form.desc = this.$t('shape.explain[2].msg')
            }
            if (name === this.$t('shape.explain[3].title')) {
                this.form.desc = this.$t('shape.explain[3].msg')
            }
            if (name === this.$t('shape.explain[4].title')) {
                this.form.desc = this.$t('shape.explain[4].msg')
            }
            if (name === this.$t('shape.explain[5].title')) {
                this.form.desc = this.$t('shape.explain[5].msg')
            }
            if (name === this.$t('shape.explain[6].title')) {
                this.form.desc = this.$t('shape.explain[6].msg')
            }
            if (name === this.$t('shape.explain[7].title')) {
                this.form.desc = this.$t('shape.explain[7].msg')
            }
            if (name === this.$t('shape.explain[8].title')) {
                this.form.desc = this.$t('shape.explain[8].msg')
            }

            this.popupAssess = true
        }
    }
}
</script>
<style lang="less" scoped>
.body-assessment-list {
    padding: 0 11px;
    margin-top: 20px;
    // background-color: #122349;
}

.data-list {
    width: 351px;
    height: 92px;
    text-align: left;
    background: url(../../assets/images/report/bj31.png) center no-repeat;
    background-size: 100% 100%;
    margin-bottom: 10px;

    .thermal-item {
        padding-top: 12px;

        .name {
            img {
                width: 10px;
                height: 10px;
            }
        }
    }

    span {
        font-size: 12px;
        color: #ffffff;
    }

    i {
        font-style: normal;
        margin: 10px 10px;
        background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .shape-list {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        .title {
            font-size: 12px;
            color: #8AA3BE;
            // padding-left: 28px;
        }

        img {
            width: 10px;
            height: 2px;
            margin: 0px 13px 3px 13px;
        }

        .symbol-ico {
            min-width: 10px;
            height: 2px;
            // margin-left: 16px;
            background: url('../../assets/images/report/symbol_ico.png') repeat-x;
            background-size: cover;
        }

        .val-list {
            display: inline-block;
            width: 154px;
            height: 22px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            // background-color: #CB4745;
            background-color: #33C070;
            border-radius: 10px;
        }

        .shape-status {
            text-align: left;
            width: 66px;
        }

        .status {
            font-size: 12px;
            color: #33C070;
        }

        .status-color {
            color: #E64444;
        }

        .val-color {
            background-color: #CB4745;
        }
    }

    /deep/.body-composition-popup .popup-container>h5 {
        padding: 16px 5px !important;
    }
}

&.en-US,
&.it-IT,
&.fr-FR,
&.ru-RU,
&.es-ES,
&.pt-PT {
    .thermal-item {
        .name {
            img {
                width: 10px;
                height: 10px;
            }
        }
    }
}
&.ar-AR{
    .popup-container{
        .content p{
            text-align: right;
            margin-right: 30px;
        }
    }
    .heard {
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        padding-right: 10px;
        align-items: center;

        .posture-name {
            font-size: 12px;
            color: #ffffff;
        }

        .help-icon {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }
    .data-list{
           .shape-list {
        margin-top: 8px;}

    }
        .thermal-item {
        padding-top: 0px;}
   .thermal-item .shape-list .val-list{
    direction: ltr;  
   }
}
</style>
<style>
.body-composition-popup .popup-container>h5 {
    word-break: break-word;
    padding: 16px 5px !important;
}
</style>



