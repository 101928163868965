import { render, staticRenderFns } from "./BodyData.vue?vue&type=template&id=4f629049&scoped=true&"
import script from "./BodyData.vue?vue&type=script&lang=js&"
export * from "./BodyData.vue?vue&type=script&lang=js&"
import style0 from "./BodyData.vue?vue&type=style&index=0&id=4f629049&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f629049",
  null
  
)

export default component.exports